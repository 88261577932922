<template>
    <mision-module-component/>
</template>

<script>
    import MisionModuleComponent from "@/components/admin/MisionModuleComponent";
    
    export default {
        name: "MisionModule",
        title: "Misión | Private",
        components: {MisionModuleComponent}
    }
</script>

<style scoped>

</style>